import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, Grid, Paper } from "@mui/material";
import { useTheme } from "@mui/styles";
import { TypographyBold } from "../../components/TypographyBold";
import { PDPModel } from "../../models/PDPModel";
import { User } from "../../models/User";
import { userIsMentee, userIsMentor } from "../../services/personalDevelopmentPlan";
import { ItemComponent } from "./ItemComponent";

export const Goals = ({
  pdp,
  updatePDP,
  assignedHRRep,
  user,
  mobile,
}: {
  pdp: PDPModel;
  updatePDP: (pdp: PDPModel) => void;
  assignedHRRep: User;
  user: User;
  mobile: boolean;
}) => {
  const theme = useTheme();
  const addGoal = () => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals = tempPDP.goals.concat({
      goal: "",
      createdAt: new Date(),
      lastUpdated: new Date(),
    });
    updatePDP(tempPDP);
  };

  const setGoal = (goalValue: string, g: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals[g].goal = goalValue;
    tempPDP.goals[g].lastUpdated = new Date();
    updatePDP(tempPDP);
  };

  const checkGoal = (checked: boolean, g: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals[g].completed = checked;
    updatePDP(tempPDP);
  };

  const deleteGoal = (g: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals.splice(g, 1);
    updatePDP(tempPDP);
  };

  const disabled = userIsMentor(pdp, user) || assignedHRRep.id === user.id;

  const canTick = userIsMentee(pdp, user);

  return (
    <>
      {pdp.goals.map((goal, g) => (
        <Paper
          sx={{
            p: theme.spacing(4),
            mb: theme.spacing(3),
            borderRadius: "10px",
          }}
          key={g}
        >
          <ItemComponent
            title="Goal"
            value={goal.goal}
            set={(event) => setGoal(event.target.value, g)}
            remove={() => deleteGoal(g)}
            check={(event) => checkGoal(event.target.value === 1 ? true : event.target.checked ? true : false, g)}
            completed={goal.completed}
            createdAt={goal.createdAt}
            lastUpdated={goal.lastUpdated}
            disabled={disabled}
            canTick={canTick}
            mobile={mobile}
          />
        </Paper>
      ))}
      {!disabled && (
        <Paper>
          <Button disabled={pdp.goals.length >= 3} variant="text" onClick={() => addGoal()} fullWidth>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ p: theme.spacing(12) }}
            >
              <TypographyBold>Add a Goal</TypographyBold>
              <AddCircleIcon fontSize="large" />
            </Grid>
          </Button>
        </Paper>
      )}
    </>
  );
};
