import HelpIcon from "@mui/icons-material/Help";
import { Alert, Box, Container, Grid, IconButton, Link, Paper, Snackbar, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactRouterLink, useHistory, useParams } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { UserContext } from "../../components/PrivateRoute";
import { TypographyBold } from "../../components/TypographyBold";
import { Path } from "../../helpers/Path";
import { PDPModel } from "../../models/PDPModel";
import { User } from "../../models/User";
import { getAssignedHRRep, getMentees, getMenteesAsHR } from "../../services/mentees";
import { getPersonalDevelopmentPlan, updatePersonalDevelopmentPlan } from "../../services/personalDevelopmentPlan";
import { Goals } from "./Goals";
import { PDPHelpDialog } from "./PDPHelpDialog";
import { formatDateTime } from "../../helpers/FormatDate";

export interface PersonalDevelopmentPlanInterface {
  loading: boolean;
  openPDPHelpDialog: boolean;
  pdp?: PDPModel;
  mentees: User[];
  menteesAsHR: User[];
  assignedHRRep?: User;
}

export const PersonalDevelopmentPlan = ({ mobile }: { mobile: boolean }) => {
  const { id } = useParams<{ id: string }>();
  const user = useContext(UserContext);

  const history = useHistory();
  const theme = useTheme();

  const [state, setState] = useState<PersonalDevelopmentPlanInterface>({
    loading: true,
    openPDPHelpDialog: false,
    mentees: [],
    menteesAsHR: [],
  });

  const [updating, setUpdating] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const { loading, pdp, mentees, openPDPHelpDialog, assignedHRRep, menteesAsHR } = state;

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    const personalDevelopmentPlanFetch = getPersonalDevelopmentPlan(id, user);
    const menteesFetch = getMentees(user.id);
    const assignedHRRepFetch = getAssignedHRRep();

    Promise.all([personalDevelopmentPlanFetch, menteesFetch, assignedHRRepFetch])
      .then(([pdp, mentees, assignedHRRep]) => {
        if (assignedHRRep?.id === user.id) {
          getMenteesAsHR().then((menteesAsHR) =>
            setState({
              ...state,
              pdp,
              mentees,
              menteesAsHR,
              assignedHRRep,
              loading: false,
            })
          );
        } else {
          setState({ ...state, pdp, mentees, assignedHRRep, loading: false });
        }
      })
      .catch((err) => history.push(Path.Dashboard));

    // eslint-disable-next-line
  }, [id]);

  const renderCounter = useRef(0);

  useEffect(() => {
    if (pdp) {
      if (renderCounter.current > 1) {
        const delayDebounceFn = setTimeout(() => {
          updatePersonalDevelopmentPlan(pdp).then(() => {
            setUpdating(false);
            setShowMessage(true);
          });
        }, 1000);
        setUpdating(true);
        setShowMessage(true);
        return () => clearTimeout(delayDebounceFn);
      }
      ++renderCounter.current;
    }
    // eslint-disable-next-line
  }, [pdp]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container maxWidth="xl">
        {pdp && assignedHRRep && pdp.mentee.id !== user.id && (
          <Link
            sx={{
              textDecoration: "none",
              color: "black",
              fontSize: "large",
            }}
            underline="hover"
            component={ReactRouterLink}
            to={`${Path["Personal Development Plan"]}/${user.id}`}
          >
            Your Personal Development Plan
          </Link>
        )}
        <Box sx={{ mb: theme.spacing(4) }} />
        {assignedHRRep && mentees.length > 0 && (
          <Box>
            <TypographyBold variant="h6">Your mentees' PDPs</TypographyBold>
            <Grid container direction="column" sx={{ mb: theme.spacing(4) }}>
              {mentees
                .filter((mentee) => mentee.id !== id)
                .map((mentee, i) => (
                  <Grid item key={i}>
                    <Link
                      sx={{
                        textDecoration: "none",
                        color: "black",
                        fontSize: "large",
                      }}
                      underline="hover"
                      component={ReactRouterLink}
                      to={`${Path["Personal Development Plan"]}/${mentee.id}`}
                    >
                      {mentee.firstName} {mentee.lastName}'s Personal Development Plan
                    </Link>
                  </Grid>
                ))}
            </Grid>
          </Box>
        )}
        {assignedHRRep && menteesAsHR.length > 0 && (
          <Box>
            <TypographyBold variant="h6">All mentee's PDPs</TypographyBold>
            <Grid container direction="column" sx={{ mb: theme.spacing(4) }}>
              {menteesAsHR.map((mentee, i) => (
                <Grid item key={i}>
                  <Link
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: "large",
                    }}
                    underline="hover"
                    component={ReactRouterLink}
                    to={`${Path["Personal Development Plan"]}/${mentee.id}`}
                  >
                    {mentee.firstName} {mentee.lastName}'s Personal Development Plan
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {pdp && assignedHRRep && (
          <>
            <PDPHelpDialog
              dialogOpen={openPDPHelpDialog}
              closeDialog={() => setState({ ...state, openPDPHelpDialog: false })}
            />
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={showMessage}
              onClose={() => setShowMessage(false)}
              autoHideDuration={3000}
            >
              <Alert severity={updating ? "warning" : "success"} elevation={6} variant="filled">
                {updating ? "Saving..." : "Saved!"}
              </Alert>
            </Snackbar>
            <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: theme.spacing(1) }}>
              <TypographyBold gutterBottom variant="h6">
                {pdp.mentee.firstName + " " + pdp.mentee.lastName}
              </TypographyBold>
              <IconButton color="primary" onClick={() => setState({ ...state, openPDPHelpDialog: true })}>
                <HelpIcon fontSize="large" />
              </IconButton>
              <Paper
                sx={{
                  borderRadius: "15px",
                  p: theme.spacing(1, 2, 1, 2),
                  backgroundColor: theme.palette.common.white,
                  width: "170px",
                }}
                elevation={2}
              >
                <Typography variant="caption" color={"textSecondary"}>
                  Last Updated
                </Typography>
                <TypographyBold variant="body2" color={"textPrimary"}>
                  {pdp.lastUpdated ? formatDateTime(pdp.lastUpdated) : "Not yet updated"}
                </TypographyBold>
              </Paper>
            </Grid>
            <Goals
              pdp={pdp}
              assignedHRRep={assignedHRRep}
              updatePDP={(pdp) => setState({ ...state, pdp: { ...pdp, lastUpdated: new Date() } })}
              user={user}
              mobile={mobile}
            />
          </>
        )}
      </Container>
    </>
  );
};
