import { doc, getDoc, setDoc } from "firebase/firestore";
import { currentStaffYear } from "../helpers/FormatDate";
import { MenteesId } from "../models/Mentee";
import { PDPModel } from "../models/PDPModel";
import { User, UserID } from "../models/User";
import { db } from "./firebase";
import { getAssignedHRRep, getMentorsAndMenteesIdCurrentYear } from "./mentees";
import { getUserById } from "./users";

const PDPs = "personalDevelopmentPlans";

export const MENTOR = "mentor";
export const HR = "HR";

const getPersonalDevelopmentPlanYear = async (year: number, mentee: User, mentor: User) => {
  const pdp = await getDoc(doc(db, PDPs, PDPs, year.toString(), mentee.id));
  if (pdp.exists()) {
    return {
      ...pdp.data(),
      goals: pdp.data().goals ? pdp.data().goals : [],
      lastUpdated: pdp.data().lastUpdated ? pdp.data().lastUpdated.toDate() : undefined,
      mentee,
      mentor,
    } as PDPModel;
  }
  return {
    goals: [],
    mentee,
    mentor,
  } as PDPModel;
};

export const getPersonalDevelopmentPlanCurrentYear = async (mentee: User, mentor: User) =>
  getPersonalDevelopmentPlanYear(currentStaffYear, mentee, mentor);

export const userIsMentee = (pdp: PDPModel, user: User) => pdp.mentee.id === user.id;

export const userIsMentor = (pdp: PDPModel, user: User) => pdp.mentor.id === user.id;

const findMentorId = (menteesId: MenteesId, menteeId: UserID) => menteesId[menteeId];

export const getPersonalDevelopmentPlan = async (menteeId: UserID, user: User) => {
  const menteesId = await getMentorsAndMenteesIdCurrentYear();
  const mentorId = findMentorId(menteesId, menteeId);
  const assignedHRRep = await getAssignedHRRep();
  if (mentorId && assignedHRRep) {
    if (user.id === mentorId || user.id === menteeId || user.id === assignedHRRep.id) {
      const mentee = await getUserById(menteeId);
      const mentor = await getUserById(mentorId);
      return getPersonalDevelopmentPlanCurrentYear(mentee, mentor);
    }
  }
  return undefined;
};

export const updatePersonalDevelopmentPlan = async (pdp: PDPModel) => {
  Object.keys(pdp).forEach((key) => {
    if (pdp[key as keyof PDPModel] === undefined) {
      delete pdp[key as keyof PDPModel];
    }
  });
  const { mentor, mentee, ...pdpWithoutMentorAndMentee } = pdp;

  await setDoc(doc(db, PDPs, PDPs, currentStaffYear.toString(), pdp.mentee.id), { ...pdpWithoutMentorAndMentee });
};
