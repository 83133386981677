import { DesignRequestProductType as DesignRequestProductTypeEnum } from "../data/DesignRequestProductType";
import { DesignRequestProductType } from "../models/DesignRequestProductType";
import { DesignRequestType as DesignRequestTypeEnum } from "../data/DesignRequestType";
import { DesignRequestType } from "../models/DesignRequestType";
import { formatDateTime, formatDate } from "../helpers/FormatDate";
import { PromotionRequestObjective as PromotionRequestObjectiveEnum } from "../data/PromotionRequestObjective";
import { PromotionRequestObjective } from "../models/PromotionRequestObjective";
import { PromotionRequestType as PromotionRequestTypeEnum } from "../data/PromotionRequestType";
import { PromotionRequestType } from "../models/PromotionRequestType";
import { PromotionRequestEmailForm } from "../models/PromotionRequestEmailForm";
import { DesignRequestEmailForm } from "../models/DesignRequestEmailForm";
import { FinanceReimbursementEmailForm, FinanceRequestEmailForm } from "../models/FinanceRequestEmailForm";
import { designRequestStatus } from "./designRequests";
import { EmailTemplate } from "./emailkey";
import { promotionRequestStatus } from "./promotionRequests";
import { requestStatus } from "./requests";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";
import { SOW_WEBSITE } from "../helpers/Env";
import { Path } from "../helpers/Path";
import { Review360EmailForm } from "../models/Review360EmailForm";
import { EventFormModel } from "../models/EventFormModel";

export const sendFinanceRequestEmail = (e: FinanceRequestEmailForm) =>
  httpsCallable(
    functions,
    "sendEmail"
  )({
    toEmail: e.email,
    templateId: EmailTemplate.FINANCE_REQUEST,
    templateData: {
      subject: e.subject,
      name: e.name,
      message: e.message,
      r_id: e.request.id,
      r_amount: e.request.amount,
      r_email: e.request.staff.email,
      r_firstName: e.request.staff.firstName,
      r_lastName: e.request.staff.lastName,
      r_time: formatDateTime(e.request.submittedTime),
      r_department: e.request.department,
      r_description: e.request.description,
      r_status: requestStatus(e.request),
      r_approvedTime: e.request.approvedTime ? formatDateTime(e.request.approvedTime) : "",
      r_declinedTime: e.request.declinedTime ? formatDateTime(e.request.declinedTime) : "",
      website: e.website,
    },
  });

export const sendFinanceReimbursementEmail = (e: FinanceReimbursementEmailForm) =>
  httpsCallable(
    functions,
    "sendEmail"
  )({
    toEmail: e.email,
    templateId: EmailTemplate.FINANCE_PAYMENT,
    templateData: {
      subject: e.subject,
      name: e.name,
      message: e.message,
      r_id: e.request.id,
      r_amount: e.request.amount,
      r_email: e.request.staff.email,
      r_firstName: e.request.staff.firstName,
      r_lastName: e.request.staff.lastName,
      r_time: formatDateTime(e.request.submittedTime),
      r_department: e.request.department,
      r_description: e.request.description,
      r_status: requestStatus(e.request),
      r_approvedTime: e.request.approvedTime ? formatDateTime(e.request.approvedTime) : "",
      r_declinedTime: e.request.declinedTime ? formatDateTime(e.request.declinedTime) : "",
      website: e.website,
      // r_receiptAmount: e.request.receiptAmount,
      // r_receiptURLs: e.request.receiptURLs
      //   ? e.request.receiptURLs.join(", ")
      //   : "",
      r_paid: e.request.paid === true ? "PAID" : "PENDING",
      r_paidAmount: e.request.paidAmount ? " $" + e.request.paidAmount : "",
      r_paidTime: e.request.paidTime ? formatDateTime(e.request.paidTime) : "",
      r_comment: e.request.comment,
    },
  });

export const sendDesignRequestEmail = (e: DesignRequestEmailForm) =>
  httpsCallable(
    functions,
    "sendEmail"
  )({
    toEmail: e.email,
    templateId: EmailTemplate.DESIGN_REQUEST,
    templateData: {
      subject: e.subject,
      message: e.message,
      r_id: e.designRequest.id,
      r_email: e.designRequest.staff.email,
      r_firstName: e.designRequest.staff.firstName,
      r_lastName: e.designRequest.staff.lastName,
      r_time: formatDateTime(e.designRequest.submittedTime),
      r_department: e.designRequest.department,
      r_type: convertTypeToArray(e.designRequest.type),
      r_details: e.designRequest.details,
      r_productType: convertProductTypeToArray(e.designRequest.productType),
      r_theme: e.designRequest.theme,
      r_example: e.designRequest.example,
      r_keyMessage: e.designRequest.message,
      r_dueDate: e.designRequest.dueDate ? formatDate(e.designRequest.dueDate) : "",
      r_multipleDrafts: e.designRequest.multipleDrafts ? "Yes" : "No",
      r_runTheDesign: e.designRequest.runTheDesign ? "Yes" : "No",
      r_extraInformation: e.designRequest.extraInformation,
      r_detailsOfEventOrProject: e.designRequest.detailsOfEventOrProject,
      r_status: designRequestStatus(e.designRequest),
      r_approvedTime: e.designRequest.approvedTime ? formatDateTime(e.designRequest.approvedTime) : "",
      r_declinedTime: e.designRequest.declinedTime ? formatDateTime(e.designRequest.declinedTime) : "",
      r_completedTime: e.designRequest.completedTime ? formatDateTime(e.designRequest.completedTime) : "",
      website: e.website,
    },
  });

export const sendPromotionRequestEmail = (e: PromotionRequestEmailForm) =>
  httpsCallable(
    functions,
    "sendEmail"
  )({
    toEmail: e.email,
    templateId: EmailTemplate.PROMOTION_REQUEST,
    templateData: {
      subject: e.subject,
      name: e.name,
      message: e.message,
      r_id: e.promotionRequest.id,
      r_email: e.promotionRequest.staff.email,
      r_firstName: e.promotionRequest.staff.firstName,
      r_lastName: e.promotionRequest.staff.lastName,
      r_time: formatDateTime(e.promotionRequest.submittedTime),
      r_department: e.promotionRequest.department,
      r_details: e.promotionRequest.details,
      r_targetAudience:
        e.promotionRequest.targetAudience === "Other"
          ? e.promotionRequest.targetAudienceOtherText
          : e.promotionRequest.targetAudience,
      r_keyMessage: e.promotionRequest.message,
      r_type: convertPromotionTypeToArray(e.promotionRequest.type),
      r_theme: e.promotionRequest.theme,
      r_example: e.promotionRequest.example,
      r_postWhen: e.promotionRequest.postWhen,
      r_filesToInclude: e.promotionRequest.filesToInclude,
      r_askForApproval: e.promotionRequest.askForApproval ? "Yes" : "No",
      r_objective: convertObjectiveToArray(e.promotionRequest.objective),
      r_extraInformation: e.promotionRequest.extraInformation,
      r_status: promotionRequestStatus(e.promotionRequest),
      r_approvedTime: e.promotionRequest.approvedTime ? formatDateTime(e.promotionRequest.approvedTime) : "",
      r_declinedTime: e.promotionRequest.declinedTime ? formatDateTime(e.promotionRequest.declinedTime) : "",
      r_completedTime: e.promotionRequest.completedTime ? formatDateTime(e.promotionRequest.completedTime) : "",
      website: e.website,
    },
  });

export const sendReview360Email = (e: Review360EmailForm) =>
  httpsCallable(
    functions,
    "sendEmail"
  )({
    toEmail: e.email,
    templateId: EmailTemplate.REVIEWS_360,
    templateData: {
      title: e.title,
      subject: e.subject,
      message: e.message,
      r_email: e.reviewee.email,
      r_year: e.year,
      r_term: e.term,
      r_cutOffDate: e.cutOffDate,
      website: SOW_WEBSITE + Path["360 Reviews"],
    },
  });

export const sendEventFormEmail = (toEmail: string, subject: string, message: string, eventForm: EventFormModel) =>
  httpsCallable(
    functions,
    "sendEmail"
  )({
    toEmail: toEmail,
    templateId: EmailTemplate.EVENT_FORM,
    templateData: {
      ...eventForm,
      subject,
      message,
      submittee: eventForm.submittee.firstName + " " + eventForm.submittee.lastName,
      submittedTime: eventForm.submittedTime ? formatDateTime(eventForm.submittedTime) : "",
      eventStartTime: eventForm.eventStartTime ? formatDateTime(eventForm.eventStartTime) : "",
      eventEndTime: eventForm.eventEndTime ? formatDateTime(eventForm.eventEndTime) : "",
      registrationOpenTime: eventForm.registrationOpenTime ? formatDateTime(eventForm.registrationOpenTime) : "",
      registrationCloseTime: eventForm.registrationCloseTime ? formatDateTime(eventForm.registrationCloseTime) : "",
      website: SOW_WEBSITE + Path["Event Forms"],
    },
  });

const convertTypeToArray = (type: DesignRequestType) => {
  return Object.entries(type)
    .filter(([t, b]) => {
      if (t === "otherText" && type.other) {
        return true;
      } else if (type.other === true) {
        return false;
      } else {
        return b;
      }
    })
    .map(([t, b]) => {
      if (t === "otherText" && type.other) {
        return b;
      } else {
        return (DesignRequestTypeEnum as any)[t];
      }
    })
    .join(", ");
};

const convertObjectiveToArray = (objective: PromotionRequestObjective) => {
  return Object.entries(objective)
    .filter(([t, b]) => {
      if (t === "otherText" && objective.other) {
        return true;
      } else if (objective.other === true) {
        return false;
      } else {
        return b;
      }
    })
    .map(([t, b]) => {
      if (t === "otherText" && objective.other) {
        return b;
      } else {
        return (PromotionRequestObjectiveEnum as any)[t];
      }
    })
    .join(", ");
};

const convertProductTypeToArray = (type: DesignRequestProductType) => {
  return Object.entries(type)
    .filter(([t, b]) => {
      if (t === "otherText" && type.other) {
        return true;
      } else {
        return b;
      }
    })
    .map(([t, b]) => {
      if (t === "otherText" && type.other) {
        return b;
      } else {
        return (DesignRequestProductTypeEnum as any)[t];
      }
    })
    .join(", ");
};

const convertPromotionTypeToArray = (type: PromotionRequestType) => {
  return Object.entries(type)
    .filter(([t, b]) => {
      if (t === "otherText" && type.other) {
        return true;
      } else if (type.other === true) {
        return false;
      } else {
        return b;
      }
    })
    .map(([t, b]) => {
      if (t === "otherText" && type.other) {
        return b;
      } else {
        return (PromotionRequestTypeEnum as any)[t];
      }
    })
    .join(", ");
};
