import { Grid, IconButton, TextField, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { StatusSelect } from "./StatusSelect";
import { useState } from "react";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { formatDateTime } from "../../helpers/FormatDate";

export const ItemComponent = ({
  title,
  value,
  set,
  remove,
  check,
  completed,
  createdAt,
  lastUpdated,
  disabled,
  canTick,
  mobile,
}: {
  title: string;
  value: string;
  set: (event: any) => void;
  remove: () => void;
  check: (event: any) => void;
  completed: boolean | undefined;
  createdAt: Date;
  lastUpdated: Date;
  disabled: boolean | undefined;
  canTick: boolean | undefined;
  mobile: boolean;
}) => {
  const [openRemove, setOpenRemove] = useState<boolean>(false);

  return (
    <>
      <ConfirmationModal
        title={"Delete Goal?"}
        content={"This will delete the goal."}
        confirmText={"Yes"}
        cancelText={"No"}
        dialogOpen={openRemove}
        closeDialog={() => setOpenRemove(false)}
        doAction={remove}
      />
      <Grid container justifyContent="space-between">
        <Typography variant="caption" color={"textSecondary"}>
          Created: {formatDateTime(createdAt)}
        </Typography>
        <Typography variant="caption" color={"textSecondary"}>
          Updated: {formatDateTime(lastUpdated)}
        </Typography>
      </Grid>
      <Grid container direction="row-reverse" alignItems="center">
        {!disabled && (
          <IconButton color="error" aria-label="delete" component="span" onClick={() => setOpenRemove(true)}>
            <ClearIcon />
          </IconButton>
        )}
        <StatusSelect handleChange={check} completed={completed} canTick={canTick} mobile={mobile} />
        <Grid item xs>
          <TextField
            sx={{
              [`& fieldset`]: {
                borderRadius: "10px",
              },
            }}
            size="small"
            disabled={disabled}
            placeholder={`Enter your ${title.toLowerCase()}`}
            onChange={set}
            value={value}
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};
