import { Alert, Link } from "@mui/material";
import { createElement } from "react";
import { Redirect, Route } from "react-router-dom";
import { DEPLOYMENT_ENVIRONMENT } from "../helpers/Env";
import { Path } from "../helpers/Path";
import { TypographyBold } from "./TypographyBold";

export const PurePublicRoute = (
  { component, currentUser, ...rest }: any // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  const routeComponent = (
    props: any // eslint-disable-line @typescript-eslint/no-explicit-any
  ) =>
    !currentUser ? (
      <>
        {DEPLOYMENT_ENVIRONMENT === "development" && (
          <Alert variant="filled" severity="info">
            <TypographyBold>
              This is the test/development environment. Please go to{" "}
              <Link
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                underline="hover"
                onClick={() => {
                  window.location.href = "https://sowaustralia.com/";
                }}
              >
                the main website
              </Link>
              . Your changes on this website will not be effective.
            </TypographyBold>
          </Alert>
        )}
        {createElement(component, props)}
      </>
    ) : (
      <Redirect to={{ pathname: Path.Dashboard }} />
    );
  return <Route {...rest} render={routeComponent} />;
};
